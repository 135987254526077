import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT IS DSCR?",
      answer: (
        <>
          <p>
            DSCR stands for Debt Service Coverage Ratio, which is a comparison
            of a property’s cash flow to its debt obligations. Lenders will look
            at this ratio to see whether the property you want to purchase has
            enough positive cash flow to cover the loan.
          </p>
        </>
      ),
    },
    {
      question: "WHAT IS A GOOD DSCR RATIO?",
      answer: (
        <>
          <p>
            Lenders typically want to see a debt service coverage ratio between
            1.25 to 1.50, which indicates there will be enough net operating
            income to cover the loan.
          </p>
          <p className="mb-0">
            For reference, a DSCR of 1.0 means there is just enough cash flow to
            cover the loan and that you’ll be breaking even. This is a
            vulnerable position to be in, and lenders are wary that the mortgage
            might not get paid if cash flow dips. A DSCR of 1.25 to 1.50 means
            there’s enough cash flow to cover the loan, plus some additional
            buffer, which makes lenders much more comfortable.
          </p>
        </>
      ),
    },
    {
      question: "WHY DOES DSCR MATTER TO INVESTORS?",
      answer: (
        <>
          <p>
            When you apply for a home loan, lenders will look at your personal
            income and compare it to your expenses. The calculation tells a
            lender whether you have enough money in your budget for a monthly
            mortgage payment.
          </p>

          <p>
            This can be problematic if you're an investor, especially if you
            have multiple properties in your portfolio. Your monthly debt
            payments could very well be more than your personal income,
            automatically disqualifying you from obtaining a loan.
          </p>

          <p>
            That's why DSCR, where lenders instead look at cash flow and
            personal income is taken out of the equation, is great for
            investors.
          </p>
        </>
      ),
    },

    {
      question: "WHAT ARE THE PROS AND CONS OF A DSCR LOAN?",
      answer: (
        <>
          <p>
            As with any type of home loan, it’s important to understand both the
            pros and cons.
          </p>
          <p className="text-bold">Pros</p>
          <ul className="list-disc pl-6">
            <li>Your personal income is not a factor in qualifying</li>
            <li>Faster application and closing</li>
            <li>Easy to scale because there’s no limit on properties</li>
          </ul>
          <p className="text-bold">Cons</p>
          <ul className="list-disc pl-6">
            <li>A hefty down payment of 20% to 25%</li>
            <li>May have a higher interest rate than a traditional mortgage</li>
            <li>Not designed for large investments over $5 million</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Investor Home Loans | DSCR Investor Loans | Accel Mortgage"
        description="At Accel, we specialize in DSCR investor home loans where qualification is based on the property’s cash flow, rather than your personal income. Learn more here!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Investor Home Loans: Funding Your Future</h1>
              <p>
                Whether you’re a first-time real estate investor or a seasoned
                pro building your portfolio, you’re in the right place. At
                Accel, we specialize in DSCR loans where qualification is based
                on the property’s cash flow rather than your personal income,
                making investing in California real estate easier.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/5.0 Investors/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>The Benefits of a DSCR Investor Loan</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">
                  No Income or Job History Required
                </h3>
                <p className="mb-0">
                  Lenders only look at the cash flow of the property you’d like
                  to purchase.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">
                  First-Time Investors Can Qualify
                </h3>
                <p className="mb-0">
                  Take that important first step, become a real estate investor,
                  and start reaping the benefits.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">
                  No Limit on the Number of Properties
                </h3>
                <p className="mb-0">
                  Take advantage of opportunities to scale up as you grow your
                  real estate investment business.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">
                  Loan Amounts up to $5 Million
                </h3>
                <p className="mb-0">
                  There are lots of options for those who qualify—everything
                  from starter properties to larger investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>What Does it Take to Qualify?</h2>
              <p>
                If you’re interested in a DSCR investor loan, the qualifications
                include:
              </p>
              <ul className="styled-list-arrow">
                <li>A DSCR ratio of 1.25 or higher</li>
                <li>Down payment of 20 - 25%</li>
                <li>A minimum credit score of 640 (can vary by lender)</li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/5.0 Investors/Investors.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
